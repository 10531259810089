import { Box, Image, Text } from '@chakra-ui/react'
import { Button, message } from 'antd';
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import logo from '../Think_Turbo_logo.png';

function Login() {
    const [URLSearchParams] = useSearchParams();
    
	// const serverUrl = "https://nobrokerserver.officeink.live";
	const serverUrl = "http://localhost:5000";

	const googleLogin = async () => {
		window.location.href = `${serverUrl}/auth/google?id=` + URLSearchParams.get("id");
		console.log("api hit!");
		localStorage.setItem("id", JSON.stringify(URLSearchParams.get("id")));
	};
    
    useEffect(() => {
    if(URLSearchParams.get("status")==="403"){
        // console.log("You are not authorized to access this page")
        message.error("You are not authorized to access this page,Contact Admin")
    }
    }, [URLSearchParams])
    
	const fontSizes = {
		base: "1.3em",
		md: "1.5em",
		lg: "1.6em",
	};

  return (
    <Box
			    as="div"
			    width={{base:'100vw',md:'60vw',lg:'40vw'}}
				sx={{
					margin: "2em auto",
					p: "2em",
					backgroundColor: "#fafafa",
					borderRadius: "1rem",
					// minWidth: "40vw",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Text fontSize={fontSizes} sx={{ textAlign: "center", fontWeight: "600" }}>
				 Your Personal AI Data Analyst
				</Text>
				<Text sx={{ fontSize: "1.5em" }}>login to access tool</Text>
				<Box>
					<Image src={logo} alt="logo" sx={{ height: "15em" }} />
				</Box>
				<Box>
					<Button p="1em" my="1em" style={{display:'flex', justifyContent:'center', alignItems:'center'}} onClick={googleLogin}>
						<FcGoogle style={{ color: "#0277B5", fontSize: "2em" }} />
						<Text sx={{ fontSize: "1em", mx: "1rem" }}>
							Sign in with Google
						</Text>
					</Button>
					{/* <Text>
						Don't have an acount?
						<Link href="/signup" mx="1rem" color="#475BFF">
							Sign Up
						</Link>{" "}
					</Text> */}
				</Box>
			</Box>
    )
}

export default Login