import { Box } from "@chakra-ui/react";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  // Tooltip,
  // Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { updatePromptGuide } from "../Redux/dataRedux";
const EditableContext = React.createContext(null);

// const serverUrl = "https://nobrokerserver.officeink.live";
const serverUrl = "http://localhost:5000";

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          placeholder="write question here"
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

function PromptGuideModal({ recordData }) {
  const dataStore = useSelector((state) => state.dataReducer);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const userData = dataStore.userData;
  const currentUserRole = userData.Role;
  const isEditing = (record) => record.key === editingKey;
  const [count, setCount] = useState(1);

//   console.log("recordData in PromptGuideedits ",recordData);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (
      recordData.promptGuideList !== undefined &&
      recordData.promptGuideList !== null 
    ) {
      setDataSource(
        JSON.parse(recordData?.promptGuideList)?.map((item, index) => {
          return {
            key: index + recordData.name,
            question: item.question,
          };
        })
      );
    }
  }, [recordData.promptGuideList]);

  // const cancel = () => {
  //   setEditingKey("");
  // };

  const handleAdd = () => {
    const newData = {
      key: count,
      question: "write question here",
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = async (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // console.log("newData", newData);
    dispatch(updatePromptGuide({ key: recordData.key, PromptGuide: JSON.stringify(newData) }));
    try {
      const response = await axios.put(
        `${serverUrl}/api/assistants/updatePromptGuide/${recordData.key}`,
        {
          promptGuideList: newData,
        }
      );
    //   console.log("response", response);
    } catch (error) {
      console.log("error", error);
    }
    setDataSource(newData);
  };

  const defaultColumns = [
    {
      title: "Questions",
      dataIndex: "question",
      key: "question",
      editable: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        dataSource.length >= 1? (
          <>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
            >
          <a >Delete</a>
          </Popconfirm>
          </>
        ) :null,
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleDelete = async (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    // console.log("newData after deletion", newData);
    dispatch(updatePromptGuide({ key: recordData.key, PromptGuide: JSON.stringify(newData) }));
    try {
      const response = await axios.put(
        `${serverUrl}/api/assistants/updatePromptGuide/${recordData.key}`,
        {
          promptGuideList: newData,
        }
      );
    //   console.log("response", response);
    } catch (error) {
      console.log("error", error);
    }
    setDataSource(newData);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Link onClick={showModal}>View</Link>
      <Modal
        title={`${recordData.name} Assistant Example Prompts`}
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Box>
          <Box className="flex justify-between">
            {/* <Typography className="text-gray-900 font-bold">
                {recordData.name} Assistant Glossary
              </Typography> */}
            {currentUserRole === "Owner" && (
              <Button className="m-2" onClick={handleAdd}>
                Add New Example
              </Button>
            )}
          </Box>
          <Form form={form} component={false}>
            <Table
              components={{ body: { row: EditableRow, cell: EditableCell } }}
              bordered
              dataSource={dataSource}
              columns={columns}
              rowClassName="editable-row"
              pagination={{ pageSize: 5 }}
            />
          </Form>
        </Box>
      </Modal>
    </>
  );
}

export default PromptGuideModal;
