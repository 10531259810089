import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Menu, Popover, Typography, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSqlThreadName, updateVisThreadName } from "../Redux/dataRedux";

// const serverUrl = "https://nobrokerserver.officeink.live";
const serverUrl = "http://localhost:5000";

function ThreadName({ thread }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dataReducer);
  const currTab = data.currTab;
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(thread.ThreadName);

  const tabId = thread.TabId;
    const threadName = thread.ThreadName;
    const MsgJson = thread.MsgJson;
    const tabType = tabId?.substring(0, 3);


  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  const handleBlur = async () => {
    setIsEditing(false);
    // Only make the API request if the name has actually changed

    if (newName !== threadName) {
      console.log("newName in handleBlur ", newName);
      if (tabType === "sql") {
        await axios.put(
          `${serverUrl}/api/sql/updateMsgThread/${thread.ThreadId}`,
          {
            ThreadName: newName,
            MsgJson: MsgJson,
            TabId: tabId,
            UserId_fk: thread.UserId_fk,
          }
        );

        dispatch(
          updateSqlThreadName({
            threadId: thread.ThreadId,
            newThreadName: newName,
          })
        );
      } else if (tabType === "vis") {
        await axios.put(
          `${serverUrl}/api/vis/updateVisMsgThread/${thread.ThreadId}`,
          {
            ThreadName: newName,
            MsgJson: MsgJson,
            TabId: tabId,
            UserId_fk: thread.UserId_fk,
          }
        );

        dispatch(
          updateVisThreadName({
            threadId: thread.ThreadId,
            newThreadName: newName,
          })
        );
      }
    }
  };
 
  const genShareLink = (e) => {
    e.stopPropagation() 
    // Here you can generate a shareable link and copy it to the clipboard
    const link=`http://localhost:3000/playground/${tabType}/${tabId}`
    console.log("link generated ", link);
    navigator.clipboard.writeText(link);
    message.success("Link copied to clipboard!");
    console.log("link copied to clipboard ");

  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button type="Link" onClick={genShareLink} ghost>
          Share
        </Button>
      </Menu.Item>
      <Menu.Item key="1">
        <Button type="Link" onClick={handleDoubleClick} ghost>
          Rename
        </Button>
      </Menu.Item>
    </Menu>
  );
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return isEditing ? (
    <input
      type="text"
      value={newName}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleBlur();
        }
      }}
      autoFocus
    />
  ) : (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography onDoubleClick={handleDoubleClick}>
          {thread.ThreadName}
        </Typography>
        <Popover
          content={menu}
          open={open}
          onOpenChange={handleOpenChange}
          trigger="click"
        >
          <EllipsisOutlined
            onClick={(e) => e.stopPropagation()}
            className="fade-button"
            color="black"
            style={{
              position: "absolute",
              left: "10.98rem",
              zIndex: "100",
              display: currTab === thread.TabId ? "block" : "none",
            }}
          />
        </Popover>
      </div>
    </>
  );
}

export default ThreadName;
