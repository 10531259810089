import { createSlice} from "@reduxjs/toolkit";

export const dataSlice = createSlice({
    name: "dataReducer",
    initialState: {
        tableData:{},
        sqlThreadIds:{},
        sqlMsgThreads:[],
        visMsgThreads:[],
        visThreadIds:{},
        assistantIDs:[],
        VisAssistantID:{},
        currTab:"sql",
        userData:{},
        sqlTabCount: 1,
        visTabCount: 1,
        tabs: [],
        isVisualizer: false,
        Members:[],
        defaulSelectedAssistant:'',
    },
    reducers: {
      addData: (state, action) => {
        state.tableData[action.payload.tabId] = action.payload.tableData;
      },
      updateData: (state, action) => {
        state.tableData[action.payload.tabId] = action.payload.tableData;
      },
      deleteData: (state, action) => {
        delete state.tableData[action.payload];
      },
        addUser: (state, action) => {
            state.userData = action.payload;
        },
        insertUserInfo: (state, action) => {
            state.userData[action.payload.key] = action.payload.value;
        },
        
         incrementSqlTabCount: (state) => {
            state.sqlTabCount += 1;
          },
          incrementVisTabCount: (state) => {
            state.visTabCount += 1;
          },
          decrementSqlTabCount: (state) => {
            state.sqlTabCount -= 1;
          },
          decrementVisTabCount: (state) => {
            state.visTabCount -= 1;
          },
          updateCurrTab: (state, action) => {
            state.currTab = action.payload;
          },
          addTab: (state, action) => {
            // console.log("tab added:: ", action.payload)
            state.tabs.push(action.payload);
          },
          removeTab: (state, action) => {
            state.tabs = state.tabs.filter((tab) => tab.key !== action.payload);
            // console.log("tab removed from store and len ", state.tabs);
          },
          updateIsVisualizer: (state, action) => {
            state.isVisualizer = action.payload;
          },
          addThreadIds: (state, action) => {
            state.sqlThreadIds[action.payload.tabId] = action.payload.threadId;
          },  
          addMsgThreads: (state, action) => {
            state.sqlMsgThreads=action.payload;
          },
          updateSqlThreadName: (state, action) => {
            const thread = state.sqlMsgThreads.find(thread => thread.ThreadId === action.payload.threadId);
            if (thread) {
              thread.ThreadName = action.payload.newThreadName;
            }
          },
          addVisMsgThreads: (state, action) => {
            state.visMsgThreads=action.payload;
          },
          updateVisThreadName: (state, action) => {
            const thread = state.visMsgThreads.find(thread => thread.ThreadId === action.payload.threadId);
            if (thread) {
              thread.ThreadName = action.payload.newThreadName;
            }
          },
          addVisThreadIds: (state, action) => {
            state.visThreadIds[action.payload.tabId] = action.payload.threadId;
          },
          updateTabName: (state, action) => {
            const tab = state.tabs.find(tab => tab.key === action.payload.tabId);
            if (tab) {
              tab.label = action.payload.newTabName;
              if(tab.key===state.tabs[0].key){
                tab.closable=true
              }
            }
          },
          storeAssistantID: (state, action) => {
            state.assistantIDs=action.payload;
          },
          updateAssistantID: (state, action) => {
            const uniquekey =state.assistantIDs.find((assistant) => assistant.key === action.payload.key);
            if (uniquekey) {
              uniquekey.AssistantID = action.payload.AssistantID;
              uniquekey.Name = action.payload.Name;
            }
          },
          updateGlossary: (state, action) => {
            const uniquekey =state.assistantIDs.find((assistant) => assistant.key === action.payload.key);
            if (uniquekey) {
              uniquekey.Glossary = action.payload.Glossary;
            }
          },
          updatePromptGuide: (state, action) => {
            const uniquekey =state.assistantIDs.find((assistant) => assistant.key === action.payload.key);
            if (uniquekey) {
              uniquekey.PromptGuide = action.payload.PromptGuide;
            }
          },
          deleteAssistantID: (state, action) => {
            state.assistantIDs=state.assistantIDs.filter((assistant) => assistant.AssistantID !== action.payload);
          },
          addAssistantID: (state, action) => {
            state.assistantIDs.push(action.payload);
          },
          addVisAssistantID: (state, action) => {
            state.VisAssistantID=action.payload;
          },
          addMembers: (state, action) => {
            state.Members=action.payload;
          },
          addNewMember: (state, action) => {
            state.Members.push(action.payload);
          },
          deleteMember: (state, action) => {
            state.Members=state.Members.filter((member) => member.email !== action.payload);
          },
          updateMember: (state, action) => {
            const member = state.Members.find((member) => member.email === action.payload.Email);
            if (member) {
              member.role = action.payload.role;
            }
          },
          addDefaulSelectedAssistant: (state, action) => {
            state.defaulSelectedAssistant=action.payload;
          },
    },
});

export const {updatePromptGuide,updateGlossary,addDefaulSelectedAssistant,updateMember,deleteMember,addNewMember,addMembers,addAssistantID,deleteAssistantID,addVisAssistantID,updateAssistantID,storeAssistantID,updateTabName,addVisThreadIds,updateVisThreadName,addVisMsgThreads,updateSqlThreadName,addMsgThreads,addThreadIds,addData ,updateCurrTab,deleteData,addUser,insertUserInfo,incrementVisTabCount,incrementSqlTabCount,decrementVisTabCount,decrementSqlTabCount,addTab,removeTab,updateIsVisualizer} = dataSlice.actions;
export default dataSlice.reducer;
