import { Box } from '@chakra-ui/react'
import { Button, Typography } from 'antd'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Home() {
   const navigate=useNavigate();
    const isUserLoggedIn=localStorage.getItem('token');  
  useEffect(() => {
    if(isUserLoggedIn){
      navigate('/playground')
    }
    if(!isUserLoggedIn){
       console.log('User not logged in')
        navigate('/login')
    }
  }
  , [isUserLoggedIn,navigate])

  return (
    <>
    <Box sx={{textAlign:'center',marginTop:'4em'}}>
    <Typography.Title>Log In to access tool</Typography.Title>
     <Button>
        <Link to="/login">Log In</Link>
     </Button>
    </Box>
    </>
  )
}

export default Home