import React, { useState } from 'react';
import { Divider, Modal, Popover } from 'antd';
import { Box } from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import Title from 'antd/es/typography/Title';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const LogsModal = ({ data, id, type }) => {
  //  console.log("logs in modal-->",data[id].sqlQuery, "id-->",id)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const ModalStyles = {
    header: {
      borderBottom: "dotted"
    }
  }

  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const hide = () => {
    setClicked(false);
    setHovered(false);
  };
  const handleHoverChange = (open) => {
    setHovered(open);
    setClicked(false);
  };
  const handleClickChange = (open) => {
    setHovered(false);
    setClicked(open);
  };
  const hoverContent = <div>Copy sql</div>;
  const clickContent = <div>Copied!</div>;

  return (
    <>
      {data[id] && <ViewIcon cursor="pointer" onClick={showModal} />}

      <Modal title="Query Logs" width="fit-content" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
        styles={ModalStyles}
        footer={null}
      >
        <Box>
          <Title level={5}>
            {type === "final" ? "Final SQL Query" : `SQL Query ${id + 1}`}
          </Title>
          {/* <Divider style={{margin: '15px 0'}} /> */}
          <pre className="bg-gray-300 p-3 rounded-md ">
            <CopyToClipboard text={data[id]?.sqlQuery}>
              <Box style={{ overflow: 'auto' }} >
                <Popover
                  style={{ width: 500 }}
                  content={hoverContent}
                  trigger="hover"
                  open={hovered}
                  onOpenChange={handleHoverChange}
                >
                  <Popover
                    content={
                      <div>
                        {clickContent}
                      </div>
                    }
                    trigger="click"
                    open={clicked}
                    onOpenChange={handleClickChange}
                  >
                    <CopyOutlined onMouseLeave={hide} style={{ right: '2rem', position: 'absolute', cursor: 'pointer' }} />
                  </Popover>
                </Popover>
                <code>{data[id]?.sqlQuery}</code>
              </Box>
            </CopyToClipboard>
          </pre>
        </Box>
      </Modal>
    </>
  );
};
export default LogsModal;