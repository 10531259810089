import { DeleteOutlined, ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Box } from '@chakra-ui/react';
import { Button, Form, Input, Modal, Select, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EditUser from './EditUser';
import axios from 'axios';
import { addNewMember, deleteMember } from '../Redux/dataRedux';

// const serverUrl = "https://nobrokerserver.officeink.live";
const serverUrl = "http://localhost:5000";

function AdminPanel() {
  const dispatch = useDispatch();
  const dataStore = useSelector((state) => state.dataReducer);
  const dataSource = dataStore.Members;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { confirm } = Modal;
  const [data, setData] = useState([]);
  const userData = dataStore.userData;
  const currentUserRole = userData.Role;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();



  useEffect(() => {
    setData(([...dataSource].filter((user) => user.email !== userData.email)).map((item, index) => {
      return {
        key: item.userId,
        name: item.firstName + " " + item.lastName,
        email: item.email,
        role: item.role,
      }
    }))
  }, [dataSource, userData])


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showPromiseConfirm = (uniqueID) => {
    confirm({
      title: 'Are you sure to remove the member?',
      icon: <ExclamationCircleFilled />,
      content: 'This action cannot be undone',
      async onOk() {
        const response = await axios.delete(`${serverUrl}/api/admin/deleteMember/${uniqueID}`);
        console.log("response ", response);
        dispatch(deleteMember(uniqueID));
      },
      onCancel() { },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link ><EditUser userData={record} currentUserRole={currentUserRole} /></Link>
          <Link disabled={currentUserRole === 'Member'} onClick={() => { currentUserRole === "Owner" && showPromiseConfirm(record.email) }} ><DeleteOutlined /></Link>
        </Space>
      ),
    },
  ];


  const showAddModal = () => {
    setIsAddModalOpen(true);
  };

  const [userName, setuserName] = useState("");
  const [userEmailID, setuserEmailID] = useState("");
  const [userRole, setuserRole] = useState("");

  // Update these state variables on input change
  const handleNameChange = (e) => {
    console.log("e.target.value name ", e.target.value);
    setuserName(e.target.value);
  };

  const handleIDChange = (e) => {
    console.log("e.target.value id ", e.target.value);
    setuserEmailID(e.target.value);
  };
  const handleRoleChange = (value) => {
    console.log("Selected role ", value);
    setuserRole(value);
  };

  const handleAddOk = async () => {
    setConfirmLoading(true);
    // console.log("okk clicked");
    const response = await axios.post(`${serverUrl}/api/admin/addMember`, {
      userName: userName,
      userEmailID: userEmailID,
      userRole: userRole
    });
    console.log("response ", response);
    setTimeout(() => {

      dispatch(addNewMember({ userId: '', firstName: userName, lastName: "", email: userEmailID, role: userRole }));

      setIsAddModalOpen(false);

      setConfirmLoading(false);
      form.resetFields();

    }, 1000);
    // console.log("data in add assistant",dataSource);
  };

  const handleAddCancel = () => {
    setIsAddModalOpen(false);
  };

  return (
    <>
      <Link onClick={showModal}>Access Control</Link>
      <Modal
        title="Admin Panel"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Box className='flex justify-between'>
          <Typography.Title level={4}>Members</Typography.Title>
          <Typography type="secondary">Your role: <span>{currentUserRole}</span> <span className='text-blue-900'>({userData?.email})</span> </Typography>
          {currentUserRole === "Owner" && <Button className='m-2' onClick={showAddModal} >Add New User</Button>}
          <Modal
            title="Add New User"
            open={isAddModalOpen}
            onOk={handleAddOk}
            confirmLoading={confirmLoading}
            onCancel={handleAddCancel}
            footer={null}
          >
            <Box>
              <Form
                form={form}
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                style={{
                  maxWidth: 600,
                }}
                onSubmitCapture={(e) => {
                  e.preventDefault();
                  console.log("form submitted");
                  handleAddOk();
                }}
              >
                <Form.Item label="Name">
                  <Input onChange={(e) => handleNameChange(e)} placeholder="give the suitable name" />
                </Form.Item>
                <Form.Item label="Email">
                  <Input onChange={(e) => handleIDChange(e)} placeholder="write user Emailid" />
                </Form.Item>
                <Form.Item label="Role">
                  <Select onChange={handleRoleChange}>
                    <Select.Option value="Owner">Owner</Select.Option>
                    <Select.Option value="Member">Member</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item >
                  <Button type="primary" htmlType="submit">  {confirmLoading && (<LoadingOutlined />)}
                    Submit</Button>
                </Form.Item>
              </Form>
            </Box>
          </Modal>
        </Box>
        <Table dataSource={data} columns={columns} pagination={{ pageSize: 5 }}
        />
      </Modal>
    </>
  )
}

export default AdminPanel