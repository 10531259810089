import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  addAssistantID,
  addVisAssistantID,
  deleteAssistantID,
  updateAssistantID,
} from "../Redux/dataRedux";
import GlossaryModal from "./GlossaryEdits";
import PromptGuideModal from "./PromptGuideEdits";

// const serverUrl = "https://nobrokerserver.officeink.live";
const serverUrl = "http://localhost:5000";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true, message: `Please Input ${title}!` }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function AssistantModal() {
  const dataStore = useSelector((state) => state.dataReducer);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isVisEditing, setisVisEditing] = useState(false);
  const { confirm } = Modal;
  const visualizerID = dataStore.VisAssistantID;
  let VisAssistantID = visualizerID?.AssistantID;
  if (VisAssistantID === undefined) {
    // console.log("VisAssistantID is undefined");
    VisAssistantID = "";
  }
  const [editedVisualizerID, setEditedVisualizerID] = useState(VisAssistantID);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const userData = dataStore.userData;
  const currentUserRole = userData.Role;
  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    setData(
      dataStore.assistantIDs.map((item, index) => {
        return {
          key: item.key,
          name: item.Name,
          assistantID: item.AssistantID,
          glossaryList: item.Glossaries,
          promptGuideList: item.PromptGuide,
        };
      })
    );
  }, [dataStore.assistantIDs]);

  const edit = (record) => {
    form.setFieldsValue({ name: "", assistantID: "", ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
        dispatch(
          updateAssistantID({
            key: item.key,
            Name: row.name,
            AssistantID: row.assistantID,
          })
        );
        const response = await axios.put(
          `${serverUrl}/api/assistants/updateAssistant/${item.key}`,
          {
            assistantID: row.assistantID,
            assistantName: row.name,
          }
        );
        console.log("response", response);
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "Assistant ID",
      dataIndex: "assistantID",
      key: "assistantID",
      editable: true,
    },
    {
      title: "Glossary",
      dataIndex: "Glossary",
      key: "glossary",
      render: (_, record) => {
        return <GlossaryModal recordData={record} />;
      },
    },
    {
      title: "Prompt Guide",
      dataIndex: "PromptGuide",
      key: "promptGuide",
      render: (_, record) => {
        return <PromptGuideModal recordData={record} />;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Space size="middle">
            <Typography.Link
              disabled={editingKey !== "" || currentUserRole === "Member"}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Typography.Link>
            <Link
              disabled={currentUserRole === "Member"}
              onClick={() =>
                currentUserRole === "Owner" &&
                showPromiseConfirm(record.assistantID)
              }
            >
              <DeleteOutlined />
            </Link>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const showAddModal = () => {
    setIsAddModalOpen(true);
  };

  const [assistantName, setAssistantName] = useState("");
  const [assistantID, setAssistantID] = useState("");

  // Update these state variables on input change
  const handleNameChange = (e) => {
    console.log("e.target.value name ", e.target.value);
    setAssistantName(e.target.value);
  };

  const handleIDChange = (e) => {
    console.log("e.target.value id ", e.target.value);
    setAssistantID(e.target.value);
  };

  const handleAddOk = async () => {
    setConfirmLoading(true);
    console.log("okk clicked");
    const response = await axios.post(
      `${serverUrl}/api/assistants/addAssistant`,
      {
        assistantName: assistantName,
        assistantID: assistantID,
      }
    );
    console.log("response", response);
    setTimeout(() => {
      dispatch(
        addAssistantID({
          key: response.data.id,
          Name: assistantName,
          AssistantID: assistantID,
        })
      );
      setIsAddModalOpen(false);
      setConfirmLoading(false);
      form.resetFields();
    }, 1000);
  };

  const handleAddCancel = () => {
    setIsAddModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showPromiseConfirm = async (id) => {
    try {
      console.log("id for deletion --> ", id);
      await confirm({
        title: "Do you want to delete this assistant?",
        icon: <ExclamationCircleFilled />,
        content: "This action cannot be undone",
        async onOk() {
          const response = await axios.delete(
            `${serverUrl}/api/assistants/deleteAssistant/${id}`
          );
          console.log("response", response);
          dispatch(deleteAssistantID(id));
        },
        onCancel() {},
      });
    } catch (error) {
      console.log("Oops errors!", error);
    }
  };

  const [originalVisualizerID, setOriginalVisualizerID] =
    useState(VisAssistantID);
  useEffect(() => {
    setOriginalVisualizerID(VisAssistantID);
  }, [visualizerID]);

  return (
    <>
      <Link onClick={showModal}>Assistants</Link>
      <Modal
        title="View Assistants"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Box>
          <Box className="flex justify-between">
            <Typography className="text-gray-900 font-bold">
              Data Assistants
            </Typography>
            {currentUserRole === "Owner" && (
              <Button className="m-2" onClick={showAddModal}>
                Add New Assistant
              </Button>
            )}
          </Box>
          <Form form={form} component={false}>
            <Table
              components={{ body: { cell: EditableCell } }}
              bordered
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={{ pageSize: 5 }}
            />
          </Form>
          <Modal
            title="Add New Assistant"
            open={isAddModalOpen}
            onOk={handleAddOk}
            confirmLoading={confirmLoading}
            onCancel={handleAddCancel}
            footer={null}
          >
            <Box>
              <Form
                form={form}
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                style={{
                  maxWidth: 600,
                }}
                onSubmitCapture={(e) => {
                  e.preventDefault();
                  console.log("form submitted");
                  handleAddOk();
                }}
              >
                <Form.Item label="Name">
                  <Input
                    onChange={(e) => handleNameChange(e)}
                    placeholder="give the suitable name"
                  />
                </Form.Item>
                <Form.Item label="Assistant ID">
                  <Input
                    onChange={(e) => handleIDChange(e)}
                    placeholder="write assistant id"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {" "}
                    {confirmLoading && <LoadingOutlined />}
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Box>
          </Modal>
        </Box>
        <Box className="flex mt-3 gap-2 items-center">
          <Typography className="text-gray-900 font-bold">
            Visualizer Assistant ID:{" "}
          </Typography>
          {isVisEditing ? (
            <Input
              width={200}
              value={editedVisualizerID}
              onChange={(e) => setEditedVisualizerID(e.target.value)}
            />
          ) : (
            <span>{VisAssistantID}</span>
          )}
          {isVisEditing ? (
            <Button
              onClick={() => {
                setisVisEditing(false);
                setEditedVisualizerID(originalVisualizerID); // Reset the edited ID to the original ID
              }}
            >
              Cancel
            </Button>
          ) : (
            <Tooltip title="Add/Update ID">
              <Link
                disabled={currentUserRole === "Member"}
                onClick={() =>
                  currentUserRole === "Owner" && setisVisEditing(true)
                }
              >
                <EditOutlined />
              </Link>
            </Tooltip>
          )}
          {isVisEditing && (
            <Tooltip title="Edit ID to Save">
              <Button
                type="primary"
                disabled={editedVisualizerID === originalVisualizerID} // Disable the button if the ID hasn't changed
                onClick={async () => {
                  // Call your API to save the changes here
                  if (visualizerID === undefined) {
                    const response = await axios.post(
                      `${serverUrl}/api/assistants/addAssistant`,
                      {
                        assistantID: editedVisualizerID,
                        assistantName: "Visualizer",
                      }
                    );
                    console.log("response", response.data);
                    dispatch(
                      addVisAssistantID({
                        key: response.data.id,
                        Name: "Visualizer",
                        AssistantID: editedVisualizerID,
                      })
                    );
                  } else {
                    const response = await axios.put(
                      `${serverUrl}/api/assistants/updateAssistant/${visualizerID.key}`,
                      {
                        assistantID: editedVisualizerID,
                      }
                    );
                    console.log("response", response);
                    dispatch(
                      addVisAssistantID({
                        key: visualizerID.key,
                        AssistantID: editedVisualizerID,
                        Name: visualizerID.Name,
                      })
                    );
                  }
                  // Then, set isVisEditing back to false
                  setisVisEditing(false);
                }}
              >
                Save
              </Button>
            </Tooltip>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default AssistantModal;
