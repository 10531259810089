import './index.css';
import Navbar from "./components/Navbar";
import { ChakraProvider } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import AIdashboard from './components/AIdashboard';

function App() {

	return (
		<ChakraProvider>
			{/* <Navbar/> */}
            <main className="content">
			<Routes>
				<Route exact path="/" element={<Home/>} />
				<Route exact path="/login" element={<Login/>} />
				<Route exact path="/playground/*" element={<AIdashboard />} />
			</Routes>
			</main>
		</ChakraProvider>
	);
}

export default App;
