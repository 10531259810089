import React, { useState } from "react";
import { Button, Modal, Radio, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { updateMember } from "../Redux/dataRedux";
import axios from "axios";

// const serverUrl = "https://nobrokerserver.officeink.live";
const serverUrl = "http://localhost:5000";

const EditUser = ({ currentUserRole, userData }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(userData.role);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const memberName = userData.name;
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    console.log("value ", value);
    setLoading(true);
    const response = axios.put(
      `${serverUrl}/api/admin/updateMember/${userData.email}`,
      {
        userRole: value,
      }
    );
    console.log("response ", response);
    setTimeout(() => {
      dispatch(updateMember({ Email: userData.email, role: value }));
      setLoading(false);
      setOpen(false);
    }, 500);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        type="primary"
        disabled={currentUserRole === "Member"}
        onClick={showModal}
      >
        <EditOutlined />
      </Button>
      <Modal
        open={open}
        title={`Change the role of the ${memberName}?`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Change role
          </Button>,
        ]}
      >
        <h3>Select a new role</h3>
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            <Radio value="Owner">
              <div>
                Owner
                <br />
                <span style={{ color: "gray" }}>Can manage all members</span>
              </div>
            </Radio>
            <Radio value="Member">
              <div>
                Member
                <br />
                <span style={{ color: "gray" }}>
                  Can only view and access chats
                </span>
              </div>
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </>
  );
};
export default EditUser;
