import {
  ArrowUpOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import {
  Button,
  Input,
  Space,
  Tooltip,
  Upload,
  message,
  Avatar,
  Spin,
} from "antd";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import React, { useEffect, useRef, useState } from "react";
import DataPopup from "./DataPopup";
import { useDispatch, useSelector } from "react-redux";
import { addVisThreadIds, updateTabName } from "../Redux/dataRedux";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";

// const serverUrl = "https://nobrokerserver.officeink.live";
const serverUrl = "http://localhost:5000";

const Visualizer = () => {
  const navigate = useNavigate();
  const dataStore = useSelector((state) => state.dataReducer);
  let tabId = dataStore.currTab;
  const userId = dataStore.userData.ID;
  const tableData = dataStore.tableData[tabId];
  // const tabs = dataStore.tabs;
  const visMsgThread = dataStore.visMsgThreads;
  const dispatch = useDispatch();
  const scrollableDivRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchStatus, setFetchStatus] = useState("");
  const [threadId, setThreadId] = useState(null);

  // This function sends a message to the server and handles the response
  const sendMessage = async () => {
    try {
      // Get the user's message from the prompt
      const userMessage = prompt;

      // Find the remove file button and click it if it exists
      const remove_file_btn = document.querySelector(
        'button[type="button"][title="Remove file"]'
      );
      if (remove_file_btn) {
        remove_file_btn.click();
      }

      // If the user's message is not empty
      if (userMessage !== "") {
        setLoading(true);
        setPrompt("");

        var sysMessage;

        // Get the file ID from local storage
        let fileId = localStorage.getItem("fileId");

        // If a file ID exists and a file is attached
        if (fileId && localStorage.getItem("isfileAttached") === "true") {
          sysMessage = null;
        } else if (localStorage.getItem("isThreadStarted") === "true") {
          // If a thread has already been started
          sysMessage =
            "File ID already provided in this thread that contains the data to visualize.";
          fileId = null;
        } else {
          sysMessage = null;
        }

        const visThreadId =
          JSON.parse(localStorage.getItem("visThreadId")) || {};
        if (visThreadId[tabId] === undefined) {
          await startNewConversation();
        }
        const ThreadId = JSON.parse(localStorage.getItem("visThreadId"))[tabId];
        // Send a POST request to the server with the user's message, system message, thread ID, and file ID
        var response = await axios.post(
          `${serverUrl}/api/visualizer/getGraph`,
          {
            message: userMessage,
            sysMessage: sysMessage,
            threadId: ThreadId,
            fileId: fileId,
          }
        );
        const results = response.data.results;

        // If the results include image data
        if (results?.image_data) {
          // Append a message with the image data and description
          appendMessage(
            JSON.stringify({
              image_data: results?.image_data,
              description: results.description,
            }),
            "AI"
          );
        } else {
          // Otherwise, append a message with the description
          appendMessage(results?.description, "AI");
        }

        // Set the thread started and file attached flags in local storage
        localStorage.setItem("isThreadStarted", true);
        localStorage.setItem("isfileAttached", false);

        // Set the loading state to false
        setLoading(false);
      }
    } catch (error) {
      // If an error occurs, set the fetch status to "error" and append an error message
      setFetchStatus("error");
      appendMessage("Error: " + error.message, "AI");
      setLoading(false);
    }
  };

  // This function appends a message to the messages state
  function appendMessage(text, sender) {
    setMessages((oldMessage) => [
      ...oldMessage,
      { message: text, sender: sender },
    ]);
  }

  // This function starts a new conversation with the server
  async function startNewConversation() {
    try {
      // If a thread ID does not exist in local storage
      const visThreadId = JSON.parse(localStorage.getItem("visThreadId")) || {};

      if (visThreadId[tabId] === undefined) {
        // Send a POST request to the server to start a new conversation
        const response = await fetch(`${serverUrl}/startConversation`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        // If the response is not OK, throw an error
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        // Parse the response data
        const data = await response.json();
        // Set the thread ID in the state and local storage
        setThreadId(data?.threadId);
        visThreadId[tabId] = data?.threadId;
        localStorage.setItem("visThreadId", JSON.stringify(visThreadId));
      } else {
        // If a thread ID exists in local storage, set the thread ID in the state
        setThreadId(visThreadId[tabId]);
      }
    } catch (error) {
      // If an error occurs, log the error
      console.log("error-->", error);
    }
  }



  // This effect runs whenever the 'messages' state changes
  useEffect(() => {
      // This function fetches the data from the server
  async function fetchVisData() {
    const authToken = localStorage.getItem("token");
    try {
      const isAdded = visMsgThread.find(
        (thread) => thread.ThreadId === threadId
      );
      if (messages.length >= 1 && threadId && !isAdded) {
        let userID = [];
        userID?.push(userId);
        const res = await axios.post(
          `${serverUrl}/api/vis/addVisMsgThread`,
          {
            TabId: tabId,
            ThreadId: threadId,
            MsgJson: messages,
            UserId_fk: userID,
          },
          {
            headers: {
              Auth_Token: authToken,
            },
          }
        );

        if (res.status === 200) {
          dispatch(addVisThreadIds({ tabId: tabId, threadId: threadId }));
          dispatch(
            updateTabName({ tabId: tabId, newTabName: res.data.ThreadName })
          );
        }
      } else if (userId !== undefined && threadId !== undefined) {
        // Fetch the current thread data
        const threadRes = await axios.get(
          `${serverUrl}/api/vis/getVisMsgThread/${tabId}`,
          {
            headers: {
              Auth_Token: authToken,
            },
          }
        );
        if (threadRes.status === 200) {
          let userIds = JSON.parse(threadRes.data[0].UserId_fk);
          // Check if userId is included in the UserId_fk array
          if (!userIds?.includes(userId)) {
            // If not included, add it
            userIds?.push(userId);
          }
          const res = await axios.put(
            `${serverUrl}/api/vis/updateVisMsgThread/${threadId}`,
            {
              TabId: tabId,
              MsgJson: messages,
              UserId_fk: JSON.stringify(userIds),
            },
            {
              headers: {
                Auth_Token: authToken,
              },
            }
          );
          if (res.status === 200) {
          }
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }
    if (messages.length > 1 ) {
      fetchVisData();
    } else if (
      messages.length ===1 &&
      localStorage.getItem("isfileAttached") === "true"
    ) {
      fetchVisData();
    }

    if (scrollableDivRef.current) {
      // Scroll to the bottom of the div
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  }, [messages, threadId, visMsgThread[threadId]]); // Dependency array for the effect

  async function getVisTabMsgThread() {
    const authToken = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${serverUrl}/api/vis/getVisMsgThread/${tabId}`,
        {
          headers: {
            Auth_Token: authToken,
          },
        }
      );
      if (res.status === 200) {
        if (res.data.length > 0) {
          setThreadId(res.data[0].ThreadId);
          const visThreadId =
            JSON.parse(localStorage.getItem("visThreadId")) || {};
          visThreadId[tabId] = res.data[0].ThreadId;
          dispatch(
            updateTabName({ tabId: tabId, newTabName: res.data[0].ThreadName })
          );
          localStorage.setItem("visThreadId", JSON.stringify(visThreadId));
          return JSON.parse(res.data[0].MsgJson);
        } else {
          return null;
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("token");
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const visThreadId = JSON.parse(localStorage.getItem("visThreadId")) || {};
    if (visThreadId) {
      setThreadId(visThreadId[tabId]);

      getVisTabMsgThread().then((data) => {
        if (data) {
          setMessages(data);
        }
      });
      if (tableData !== undefined) {
        setPrompt("Make a graph to represent this data");
        setMessages([{ message: JSON.stringify(tableData), sender: "user" }]);
      }
    }
  }, []);

  // This function splits a message into parts
  function splitMessage(message) {
    var parts = [];
    // If the message is a string and contains "columns" and "rows"
    if (
      message &&
      typeof message === "string" &&
      message.includes("columns") &&
      message.includes("rows")
    ) {
      // Add the parsed message to the parts
      parts.push({ tableData: JSON.parse(message) });
    } else if (
      message &&
      typeof message === "string" &&
      message.includes("description") &&
      message.includes("image_data")
    ) {
      // Add the parsed message and image URL to the parts
      parts.push({
        imgUrl: JSON.parse(message)?.image_data,
        text: JSON.parse(message).description,
      });
    } else if (message === undefined) {
      // If there is no message, add a default text to the parts
      parts.push({ text: "No data to visualize" });
    } else {
      // If none of the above conditions are met, add the message to the parts
      parts.push({ text: message });
    }
    return parts;
  }

  // This object contains the properties for the file upload
  const props = {
    name: "file",
    action: `${serverUrl}/api/visualizer/upload`,
    method: "POST",
    headers: {
      enctype: "multipart/form-data",
    },
    // This function runs when the file upload status changes
    onChange(info) {
      // If the file is not uploading
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // Show a success message
        message.success(`${info.file.name} file uploaded successfully`);
        // console.log("File ID:", info.file.response.fileId);
        // Store the file ID and upload status in local storage
        localStorage.setItem("isfileAttached", "true");
        localStorage.setItem("fileId", info.file.response.fileId);
      } else if (info.file.status === "error") {
        // If the file upload failed, show an error message
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const [messageApi, contextHolder] = message.useMessage();
  const info = () => {
    messageApi.info("Downlaoading the graph...");
  };

  // This function downloads an image
  const handleDownload = (imageUrl) => {
    saveAs(imageUrl, "graph.png");
  };

  return (
    <>
      {contextHolder}
      <div className="pr-[.9rem] pt-1 h-full w-[-webkit-fill-available]">
        {threadId === "" ? (
          <div className="flex items-center justify-center h-full w-full">
            <Spin />
          </div>
        ) : (
          <div className="border-2 rounded-md border-black flex items-center justify-between w-full flex-col p-4 pb-0">
            {/* <h4>Welcome to Tab {tabId}</h4> */}
            <div
              ref={scrollableDivRef}
              className=" messages p-4 w-full block overflow-y-auto h-[74.5vh]"
            >
              {messages.length > 0 &&
                messages.map((message, index) => {
                  const messageParts = splitMessage(message.message);
                  return (
                    <div
                      className={
                        message.sender === "AI"
                          ? "flex gap-2 items-end justify-start py-2"
                          : "flex gap-2 items-end flex-row-reverse py-2"
                      }
                      key={tabId + index}
                    >
                      <Avatar
                        style={{
                          backgroundColor: "#fde3cf",
                          color: "#f56a00",
                        }}
                      >
                        {message.sender === "AI" ? "AI" : "H"}
                      </Avatar>
                      <div className="p-2 bg-[#e9e9e9] w-fit rounded-md">
                        {messageParts.map((part, idx) => {
                          if (part.tableData) {
                            return (
                              <>
                                <DataPopup
                                  data={{
                                    columns: part.tableData?.columns,
                                    rows: part.tableData?.rows,
                                  }}
                                  key={idx + tabId}
                                />
                              </>
                            );
                          }
                          if (part.imgUrl && part.text) {
                            return (
                              <>
                                <Box
                                  as="div"
                                  sx={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  <div className="desID">
                                    <p>{part.text}</p>
                                  </div>
                                  {part.imgUrl && (
                                    <Box sx={{ display: "flex", gap: "1rem" }}>
                                      <img
                                        src={part.imgUrl}
                                        alt="graph"
                                        className=" h-[500px]"
                                      />
                                      <Box as="div">
                                        <Tooltip
                                          title="Download"
                                          placement="top"
                                          overlayStyle={{ fontSize: "12px" }}
                                        >
                                          <Box
                                            as="button"
                                            onClick={() => {
                                              info();
                                              handleDownload(part.imgUrl);
                                            }}
                                          >
                                            <DownloadOutlined
                                              style={{
                                                position: "relative",
                                                fontSize: "xx-large",
                                                background: "cornsilk",
                                                cursor: "pointer",
                                                padding: "0.3rem",
                                                borderRadius: "0.5rem",
                                              }}
                                            />
                                          </Box>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </>
                            );
                          } else {
                            return (
                              <ReactMarkdown
                                key={idx + tabId}
                                className="text-sm overflow-hidden leading-7"
                              >
                                {part.text}
                              </ReactMarkdown>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}

              {(loading || fetchStatus === "success") && (
                <div
                  className="flex gap-2 items-end justify-start py-2"
                  key="SQLQALoader"
                >
                  <Avatar
                    style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                  >
                    AI
                  </Avatar>
                  <div className="flex items-center justify-center">
                    {loading && (
                      <div className="p-2 bg-[#e9e9e9] w-fit rounded-md">
                        <Spin />
                      </div>
                    )}
                    <div className="p-2 "></div>
                  </div>
                </div>
              )}
            </div>

            <div
              className="w-[98%] p-2 flex justify-center"
              style={{ alignItems: "center" }}
            >
              <Box sx={{ margin: "1rem" }}>
                <Upload {...props} maxCount={1}>
                  <Button style={{height: "40px"}} icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Box>

              <Space.Compact style={{ width: "100%", height: "40px" }}>
                <Input
                  name="user_input"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  disabled={loading}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && prompt !== "") {
                      appendMessage(prompt, "user");
                      sendMessage();
                    }
                  }}
                />
                <Tooltip
                  title="Send"
                  placement="top"
                  overlayStyle={{ fontSize: "12px" }}
                >
                  <Button
                    disabled={loading}
                    type="primary"
                    style={{ height: "100%" }}
                    className={
                      loading ? "bg-[rgba(0, 0, 0, 0.04)]" : "bg-[#4096ff]"
                    }
                    onClick={() => {
                      if (prompt !== "") {
                        appendMessage(prompt, "user");
                        sendMessage();
                      }
                    }}
                  >
                    <ArrowUpOutlined style={{ fontSize: "large" }} />
                  </Button>
                </Tooltip>
              </Space.Compact>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Visualizer;
