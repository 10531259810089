import { configureStore} from "@reduxjs/toolkit";
import { dataSlice } from "./dataRedux";

export default configureStore({
    reducer: {
        dataReducer: dataSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


