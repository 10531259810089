import "react-pro-sidebar/dist/css/styles.css";
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Space,
  Switch,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  BarChartOutlined,
  CodeOutlined,
  HistoryOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { Menu, ProSidebar } from "react-pro-sidebar";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrTab,
  incrementSqlTabCount,
  incrementVisTabCount,
  decrementSqlTabCount,
  decrementVisTabCount,
  addTab,
  removeTab,
  updateIsVisualizer,
  addUser,
  addMsgThreads,
  addVisMsgThreads,
  addVisAssistantID,
  storeAssistantID,
  addMembers,
  addDefaulSelectedAssistant,
} from "../Redux/dataRedux";
import SQLQA from "./SQLQA";
import Visualizer from "./Visualizer";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ThreadName from "./ThreadName";
import AdminPanel from "./AdminPanel";
import AssistantModal from "./AssistantsModal";
import { color } from "framer-motion";

// const serverUrl = "https://nobrokerserver.officeink.live";
const serverUrl = "http://localhost:5000";

const AIdashboard = () => {
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter(Boolean);
  const sharedTabType = pathParts[1];
  const sharedTabId = pathParts[2];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dataReducer);
  const sqlTabCount = data.sqlTabCount;
  const visTabCount = data.visTabCount;
  const currTab = data.currTab;
  const tabs = data.tabs;
  const sqlMsgThread = data.sqlMsgThreads;
  const sqlThreadIds = data.sqlThreadIds;
  const visThreadIds = data.visThreadIds;
  const visMsgThread = data.visMsgThreads;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [URLSearchParams] = useSearchParams();

  const user = data.userData;
  const [isloading, setIsLoading] = useState(false);

  // Create a ref for the token
  let token = useRef("");

  //fetching details of assistants
  useEffect(() => {
    let rows, visualizerData, dataSource;
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/api/assistants/getAllAssistants`
        );
        if (response.status === 200) {
          rows = response.data;
          visualizerData = rows.find((row) => row.Name === "Visualizer");
          dataSource = rows.filter((row) => row.Name !== "Visualizer");
          dispatch(storeAssistantID(dataSource));
          dispatch(addDefaulSelectedAssistant(dataSource[0].Name));
          dispatch(addVisAssistantID(visualizerData));
        }

        const resAllusers = await axios.get(
          `${serverUrl}/api/admin/getAllMemebers`
        );
        if (resAllusers.status === 200) {
          const allUsers = resAllusers.data;
          const data = allUsers.map((user) => {
            return {
              userId: user.UserId,
              firstName: user.FirstName,
              lastName: user.LastName,
              email: user.Email,
              role: user.Role,
            };
          });
          dispatch(addMembers(data));
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  // This effect runs when URLSearchParams changes
  useEffect(() => {
    // Create a cancel token for the axios request
    const cancelToken = axios.CancelToken.source();

    // Get the token from the URL search params
    token.current = URLSearchParams.get("token");
    if (!token.current && JSON.parse(localStorage.getItem("token")) == null) {
      window.location.href = "/login";
    }
    // If there's no token in the URL and local storage, redirect to login
    if (token.current) {
      localStorage.setItem("token", JSON.stringify(token.current));
    }

    // If there's no token in the URL, get it from local storage
    if (token.current == null) {
      token.current = JSON.parse(localStorage.getItem("token"));
    }

    // If there's still no token, redirect to login
    if (!token.current) {
      window.location.href = "/login";
    }

    // Fetch user information
    const fetchUser = async () => {
      try {
        // Send a POST request to the server with the token
        const res = await axios.post(
          `${serverUrl}/auth/getinfo`,
          { token: token.current },
          { cancelToken: cancelToken.token }
        );

        // If the request is successful, store the user data in local storage
        if (res.status === 200) {
          localStorage.setItem("token", JSON.stringify(token.current));
          const Resuser = res.data.user;
          if (Resuser === undefined) {
            handleLogout();
          }
          const us = {
            name: Resuser.FirstName,
            email: Resuser.Email,
            picture: Resuser.Picture,
            ID: Resuser.UserId,
            Role: Resuser.Role,
          };
          // console.log("user ", us);
          dispatch(addUser(us));
        } else {
          // If the request fails, throw an error
          throw new Error("Failed to authenticate user");
        }
      } catch (err) {
        // Handle request cancellation and other errors
        if (axios.isCancel(err)) {
          console.log("Fetch aborted!");
        } else {
          console.log(err);
        }
      }
    };
    // Call the fetchUser function
    fetchUser();
    // Cancel the axios request when the component unmounts

    const isAlreadyOpened = tabs.find((tab) => tab.key === sharedTabId);
    // if (sharedTabId === undefined) {
    //   console.log("bug check 1!! ")
    //   add("sql");
    // }
    if (sharedTabId && !isAlreadyOpened && tabs.length>0) {
      let thread;
      if (sharedTabType === "sql") {
        thread = sqlMsgThread.find((thread) => thread.TabId === sharedTabId);
      } else {
        thread = visMsgThread.find((thread) => thread.TabId === sharedTabId);
      }
      console.log("bug check 2!! ")
      add(sharedTabType, sharedTabId, thread?.ThreadName);
      dispatch(updateCurrTab(sharedTabId));
    }
    return () => {
      cancelToken.cancel();
    };
  }, [URLSearchParams]);

  const [activeKey, setActiveKey] = useState(currTab);

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  const onChange = (key) => {
    dispatch(updateCurrTab(key));
  };

  const add = (component, existTabId, threadName) => {
    var newActiveKey;
    let label = threadName ? threadName : `New ${component}`;
    if (existTabId) {
      newActiveKey = existTabId;
    } else {
      newActiveKey = uuidv4();
      newActiveKey = `${component}${newActiveKey}`;
    }
    if (component === "vis") {
      dispatch(incrementVisTabCount());
    } else {
      dispatch(incrementSqlTabCount());
    }

    let newTab = {
      label: label,
      key: `${newActiveKey}`,
      type: component,
      children: component === "sql" ? <SQLQA /> : <Visualizer />,
    };

      // console.log("bug check 2, ", tabs)
    if (tabs.length === 0) {
      newTab = {
        label: "",
        children: <SQLQA />,
        key: newActiveKey,
        type: "sql",
        closable: false,
      };
    }

    dispatch(addTab(newTab));
    dispatch(updateCurrTab(newActiveKey));
  };

  useEffect(() => {
    const activeTab = tabs.find((tab) => tab.key === currTab);
    if (activeTab) {
      setActiveKey(currTab);
      if (activeTab.type === "vis") {
        dispatch(updateIsVisualizer(true));
      } else {
        dispatch(updateIsVisualizer(false));
      }
      navigate(`/playground/${activeTab.type}/${currTab}`);
    }
  }, [currTab, tabs, navigate]);

  useEffect(()=>{
      // console.log("bug check 1, ", tabs)
      if(tabs.length === 0){
        add("sql");
      }
  },[tabs])

  const remove = (targetKey) => {
    const targetIndex = tabs.findIndex((pane) => pane.key === targetKey);
    const newPanes = tabs.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key, type } = newPanes[targetIndex - 1 < 0 ? 0 : targetIndex - 1];
      setActiveKey(key);
      dispatch(updateCurrTab(key));
    }
    const removedTab = tabs.find((tab) => tab.key === targetKey);
    dispatch(removeTab(targetKey));
    // dispatch(addTab(newPanes));
    // Decrement the appropriate tab count
    if (removedTab.type === "sql" && sqlTabCount > sqlMsgThread.length) {
      dispatch(decrementSqlTabCount());
    } else if (removedTab.type === "vis" && visTabCount > visMsgThread.length) {
      // Retrieve the visMsgThread object from localStorage
      dispatch(decrementVisTabCount());
    }
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };

  const items = [
    {
      label: <AdminPanel />,
      key: "0",
    },
    {
      label: <AssistantModal />,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Button color="black" onClick={handleLogout}>
          Logout
        </Button>
      ),
      key: "2",
    },
  ];

  const [sqlAllThreads, setSqlAllThreads] = useState([]);
  const [visAllThreads, setVisAllThreads] = useState([]);

  const [SqlthreadsToShow, setSqlthreadsToShow] = useState([]);
  const [selfSqlMsgThread, setSelfSqlMsgThread] = useState([]);

  const [VisthreadsToShow, setVisthreadsToShow] = useState([]);
  const [selfVisMsgThread, setSelfVisMsgThread] = useState([]);
  const [SelfModeOn, setSelfModeOn] = useState(true);

  useEffect(() => {
    //fetch the sqlMsg thread data
    const fetchSqlMsgThread = async () => {
      const cancelToken = axios.CancelToken.source();
      setIsLoading(true);
      try {
        // Send a POST request to the server with the token
        const res = await axios.get(
          `${serverUrl}/api/sql/getMsgThread`,
          { token: token.current },
          { cancelToken: cancelToken.token }
        );

        // If the request is successful, store the user data in local storage
        if (res.status === 200) {
          const sqlALLMsg = res.data;
          setSqlAllThreads(sqlALLMsg);
          dispatch(addMsgThreads(sqlALLMsg));
        } else {
          // If the request fails, throw an error
          throw new Error("Failed to fetch sqlMsgThread");
        }
      } catch (err) {
        // Handle request cancellation and other errors
        if (axios.isCancel(err)) {
          console.log("Fetch aborted!");
        } else {
          console.log(err);
        }
      } finally {
        setIsLoading(false);
      }
    };
    // Call the fetchSqlMsgThread function
    fetchSqlMsgThread();
  }, [sqlThreadIds]);

  useEffect(() => {
    //fetch the visMsg thread data
    const fetchVisMsgThread = async () => {
      const cancelToken = axios.CancelToken.source();
      setIsLoading(true);
      try {
        // Send a POST request to the server with the token
        const res = await axios.get(
          `${serverUrl}/api/vis/getVisMsgThread`,
          { token: token.current },
          { cancelToken: cancelToken.token }
        );

        // If the request is successful, store the user data in local storage
        if (res.status === 200) {
          const visMsgThread = res.data;
          setVisAllThreads(visMsgThread);
          dispatch(addVisMsgThreads(visMsgThread));
        } else {
          // If the request fails, throw an error
          throw new Error("Failed to fetch visMsgThread");
        }
      } catch (err) {
        // Handle request cancellation and other errors
        if (axios.isCancel(err)) {
          console.log("Fetch aborted!");
        } else {
          console.log(err);
        }
      } finally {
        setIsLoading(false);
      }
    };
    // Call the fetchVisMsgThread function
    fetchVisMsgThread();
  }, [visThreadIds]);

  useEffect(() => {
    //fetch the self sqlMsg thread data
    const fetchSelfSqlMsgThread = async () => {
      const cancelToken = axios.CancelToken.source();
      try {
        // Send a POST request to the server with the token
        setIsLoading(true);
        const res = await axios.get(
          `${serverUrl}/api/sql/getMsgThread?userId=${user.ID}`,
          { token: token.current },
          { cancelToken: cancelToken.token }
        );

        // If the request is successful, store the user data in local storage
        if (res.status === 200) {
          const sqlALLMsg = res.data;
          // setSelfSqlMsgThread([...selfSqlMsgThread, ...sqlALLMsg]);
          setSelfSqlMsgThread(sqlALLMsg);
        } else {
          // If the request fails, throw an error
          throw new Error("Failed to fetch self sqlMsgThread");
        }
      } catch (err) {
        // Handle request cancellation and other errors
        if (axios.isCancel(err)) {
          console.log("Fetch aborted!");
        } else {
          console.log(err);
        }
      } finally {
        setIsLoading(false);
      }
    };
    // Call the fetchSelfSqlMsgThread function
    fetchSelfSqlMsgThread();
  }, [user, sqlThreadIds]);

  useEffect(() => {
    //fetch the self visMsg thread data
    const fetchSelfVisMsgThread = async () => {
      const cancelToken = axios.CancelToken.source();
      setIsLoading(true);
      try {
        // Send a POST request to the server with the token
        const res = await axios.get(
          `${serverUrl}/api/vis/getVisMsgThread?userId=${user.ID}`,
          { token: token.current },
          { cancelToken: cancelToken.token }
        );

        // If the request is successful, store the user data in local storage
        if (res.status === 200) {
          const visMsgThread = res.data;
          setSelfVisMsgThread(visMsgThread);
          // setSelfVisMsgThread([...selfVisMsgThread, ...visMsgThread]);
        } else {
          // If the request fails, throw an error
          throw new Error("Failed to fetch visMsgThread");
        }
      } catch (err) {
        // Handle request cancellation and other errors
        if (axios.isCancel(err)) {
          console.log("Fetch aborted!");
        } else {
          console.log(err);
        }
      } finally {
        setIsLoading(false);
      }
    };
    // Call the fetchVisMsgThread function
    fetchSelfVisMsgThread();
  }, [user, visThreadIds]);

  const [triggerHistory, setTriggerHistory] = useState("sql");

  const handleSQLHistory = () => {
    setTriggerHistory("sql");
  };

  const handleVisualizerHistory = () => {
    setTriggerHistory("vis");
  };

  const OpenTab = (thread) => {
    const tabId = thread.TabId;
    const threadName = thread.ThreadName;
    const MsgJson = thread.MsgJson;
    const tabType = tabId?.substring(0, 3);
    const isOpened = tabs.find((tab) => tab.key === tabId);
    if (isOpened === undefined || isOpened.key !== tabId) {
      add(tabType, tabId, threadName);
      // Check if the thread's UserId_fk contains userId
      if (tabType === "sql") {
        const isThreadInSelfSqlMsgThread = selfSqlMsgThread.find(
          (selfThread) => selfThread.TabId === thread.TabId
        );
        if (
          !thread.UserId_fk.includes(user.ID) &&
          tabType === "sql" &&
          !isThreadInSelfSqlMsgThread
        ) {
          setSelfSqlMsgThread((prevThreads) => [thread, ...prevThreads]);
        }
      } else if (tabType === "vis") {
        const isThreadInSelfVisMsgThread = selfVisMsgThread.find(
          (selfThread) => selfThread.TabId === thread.TabId
        );
        if (
          !thread.UserId_fk.includes(user.ID) &&
          tabType === "vis" &&
          !isThreadInSelfVisMsgThread
        ) {
          setSelfVisMsgThread((prevThreads) => [thread, ...prevThreads]);
        }
      }
    }
    dispatch(updateCurrTab(tabId));
  };

  useEffect(() => {
    setSqlAllThreads(sqlMsgThread);
  }, [sqlMsgThread]);
  useEffect(() => {
    setVisAllThreads(visMsgThread);
  }, [visMsgThread]);

  useEffect(() => {
    if (SelfModeOn) {
      // console.warn("selfSqlMsgThread",selfSqlMsgThread)
      setSqlthreadsToShow(selfSqlMsgThread);
    } else {
      setSqlthreadsToShow(sqlMsgThread);
    }
  }, [SelfModeOn, selfSqlMsgThread, sqlMsgThread]);

  useEffect(() => {
    if (SelfModeOn) {
      setVisthreadsToShow(selfVisMsgThread);
    } else {
      setVisthreadsToShow(visMsgThread);
    }
  }, [SelfModeOn, visMsgThread, selfVisMsgThread]);

  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [showMoreErr, setShowMoreErr] = useState(false);
  const [showMoreMsg, setShowMoreMsg] = useState(null);
  useEffect(() => {
    setShowMoreErr(false);
    setShowMoreMsg(null);
  }, [SelfModeOn, triggerHistory]);

  useEffect(() => {
    dispatch(addMsgThreads(sqlAllThreads));
  }, [sqlAllThreads]);

  useEffect(() => {
    dispatch(addVisMsgThreads(visAllThreads));
  }, [visAllThreads]);

  const showMoreSql = async () => {
    setShowMoreLoading(true);
    setShowMoreErr(false);
    const cancelToken = axios.CancelToken.source();
    try {
      // Send a POST request to the server with the token
      const startIndex = SqlthreadsToShow.length;
      if (SelfModeOn) {
        const res = await axios.get(
          `${serverUrl}/api/sql/getMsgThread?userId=${user.ID}&startIndex=${startIndex}&limit=10`,
          { token: token.current },
          { cancelToken: cancelToken.token }
        );

        // If the request is successful, store the user data in local storage
        if (res.status === 200) {
          const data = res.data;
          if (data.length === 0) {
            setShowMoreErr(true);
            setShowMoreMsg('No more data');
          }
          else if (data.length < 10) {
            setShowMoreErr(true);
            setShowMoreMsg('');
          }
          setSelfSqlMsgThread([...selfSqlMsgThread, ...data]);
        } else {
          // If the request fails, throw an error
          throw new Error("Failed to fetch sqlMsgThread");
        }
      }
      else {
        const res = await axios.get(
          `${serverUrl}/api/sql/getMsgThread?startIndex=${startIndex}&limit=10`,
          { token: token.current },
          { cancelToken: cancelToken.token }
        );

        // If the request is successful, store the user data in local storage
        if (res.status === 200) {
          const data = res.data;
          if (data.length === 0) {
            setShowMoreErr(true);
            setShowMoreMsg('No more data');
          }
          else if (data.length < 10) {
            setShowMoreErr(true);
            setShowMoreMsg('');
          }
          setSqlAllThreads([...sqlAllThreads, ...data]);
        } else {
          // If the request fails, throw an error
          throw new Error("Failed to fetch sqlMsgThread");
        }
      }

    } catch (err) {
      // Handle request cancellation and other errors
      if (axios.isCancel(err)) {
        console.log("Fetch aborted!");
      } else {
        console.log(err);
      }
    }
    finally {
      setShowMoreLoading(false);
    }
  }

  const showMoreVis = async () => {
    setShowMoreLoading(true);
    setShowMoreErr(false);
    const cancelToken = axios.CancelToken.source();
    try {
      // Send a POST request to the server with the token
      const startIndex = visMsgThread.length;
      if (SelfModeOn) {
        const res = await axios.get(
          `${serverUrl}/api/vis/getVisMsgThread?userId=${user.ID}&startIndex=${startIndex}&limit=10`,
          { token: token.current },
          { cancelToken: cancelToken.token }
        );

        // If the request is successful, store the user data in local storage
        if (res.status === 200) {
          const data = res.data;
          if (data.length === 0) {
            setShowMoreErr(true);
            setShowMoreMsg('No more data');
          }
          setSelfVisMsgThread([...selfVisMsgThread, ...data]);
        } else {
          // If the request fails, throw an error
          throw new Error("Failed to fetch selfvisMsgThread");
        }
      }
      else {
        const res = await axios.get(
          `${serverUrl}/api/vis/getVisMsgThread?startIndex=${startIndex}&limit=10`,
          { token: token.current },
          { cancelToken: cancelToken.token }
        );

        // If the request is successful, store the user data in local storage
        if (res.status === 200) {
          const data = res.data;
          if (data.length === 0) {
            setShowMoreErr(true);
            setShowMoreMsg('No more data');
          }
          setVisAllThreads([...visAllThreads, ...data]);
        } else {
          // If the request fails, throw an error
          throw new Error("Failed to fetch visMsgThread");
        }
      }
    } catch (err) {
      // Handle request cancellation and other errors
      if (axios.isCancel(err)) {
        console.log("Fetch aborted!");
      } else {
        console.log(err);
      }
    }
    finally {
      setShowMoreLoading(false);
    }
  }

  // console.warn("SqlthreadsToShow",SqlthreadsToShow)

  return (
    <>
      <Box className="flex ">
        <Box
          sx={{
            "& .pro-sidebar-inner": {
              background: "#d0e3d0 !important",
              // paddingRight: "0.5rem !important",
            },
            "& .pro-icon-wrapper": {
              backgroundColor: "transparent !important",
            },
            "& .pro-inner-item": {
              padding: "0px 15px 0px 20px !important",
              color: "#000000 !important",
            },
            "& .pro-inner-item:hover": {
              color: "#868dfb !important",
            },
            "& .pro-menu-item.active": {
              backgroundColor: "white !important",
              borderRadius: "0.6rem !important",
            },
          }}
        // onMouseEnter={() => setIsCollapsed(false)}
        // onMouseLeave={() => setIsCollapsed(true)}
        >
          <ProSidebar
            width={isCollapsed ? "0px" : "220px"}
            style={{
              height: "100%",
              position: "fixed",
              backgroundColor: "#d0e3d0",
              visibility: isCollapsed ? "hidden" : "visible",
              transition: "visibility 0s, width 0.5s",
            }}
            collapsed={isCollapsed}
            onClick={(e) => e.stopPropagation()}
          >
            <Menu iconShape="circle">
              <Box>
                <img
                  src="/NOBroker.png"
                  alt="NoBroker"
                  className="h-10 mb-3 mx-auto"
                />
                <Divider style={{ margin: 0, backgroundColor: 'black', opacity: '0.3' }} />
                <Box
                  sx={{
                    display: "flex",
                    gap: "5",
                    mt: ".5rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button cl onClick={() => add("sql")}>
                    <Box className="flex gap-2 w-[100px] justify-between">
                      <Box className="flex gap-2">
                        <CodeOutlined style={{ color: "black" }} />
                        <Typography>DATA</Typography>
                      </Box>
                      <PlusOutlined style={{ color: "black" }} />
                    </Box>
                  </Button>
                  <Tooltip title="SQL History" arrow>
                    <HistoryOutlined
                      style={{
                        fontSize: "0.8rem",
                        color: triggerHistory === "sql" ? "#fc3752" : "black",
                      }}
                      onClick={handleSQLHistory}
                    />
                  </Tooltip>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "5",
                    alignItems: "center",
                    justifyContent: "center",
                    my: "0.7rem",
                  }}
                >
                  <Button onClick={() => add("vis")}>
                    <Box className="flex gap-2 w-[100px] justify-between">
                      <Box className="flex gap-2">
                        <BarChartOutlined style={{ color: "black" }} />
                        <Typography>Visualizer</Typography>
                      </Box>
                      <PlusOutlined style={{ color: "black" }} />
                    </Box>
                  </Button>
                  <Tooltip title="Visualizer History" arrow>
                    <HistoryOutlined
                      style={{
                        fontSize: "0.8rem",
                        color: triggerHistory === "vis" ? "#fc3752" : "black",
                      }}
                      onClick={handleVisualizerHistory}
                    />
                  </Tooltip>
                </Box>

                <Box sx={{ fontSize: ".875rem", lineHeight: "1.25rem" }}>
                  {/* <Divider style={{ margin: 0 }} /> */}
                  <Box className="flex items-center justify-center gap-2 mb-2">
                    {triggerHistory === "sql" ? (
                      <CodeOutlined style={{ color: "black" }} />
                    ) : (
                      <BarChartOutlined style={{ color: "black" }} />
                    )}
                    <Typography>History</Typography>
                    <Switch
                      checked={SelfModeOn}
                      onChange={() => {
                        setSelfModeOn(!SelfModeOn);
                      }}
                      size="small"
                      checkedChildren="Self"
                      unCheckedChildren="All"
                    />
                  </Box>
                  <Divider style={{ margin: 0, backgroundColor: 'black', opacity: '0.3' }} />
                  <Box className="px-1 py-1">
                    {isloading && (
                      <div className="flex items-center justify-center h-full w-full">
                        <SyncOutlined spin />
                      </div>
                    )}
                    {!isloading && (SqlthreadsToShow?.length === 0 && triggerHistory === "sql") ||
                      (VisthreadsToShow?.length === 0 && triggerHistory === "vis") && (
                        <div className="flex items-center justify-center h-full w-full">
                          No data to view
                        </div>
                      )}
                    {!isloading && triggerHistory === "sql" && SqlthreadsToShow?.length > 0 && (
                      <Box
                        sx={{ overflowX: "hidden", height: "67.5vh" }}
                        className="messages"
                      >
                        {SqlthreadsToShow.map((thread) => (
                          <Button
                            type="text"
                            key={thread.ThreadId}
                            onClick={() => OpenTab(thread)}
                            style={{
                              paddingLeft: '5px',
                              paddingRight: '5px',
                              backgroundColor:
                                currTab === thread.TabId
                                  ? "#F0F0F0"
                                  : "transparent",
                              backgroundImage:
                                currTab === thread.TabId
                                  ? "none"
                                  : "linear-gradient(to right ,transparent,#d0e3d0) !important",
                              ":hover": {
                                backgroundColor: "#F0F0F0",
                              },
                            }}
                            className="btnhoverprop "
                            block
                          >
                            <ThreadName key={thread.TabId} thread={thread} />
                          </Button>
                        ))}
                        {!showMoreLoading && !showMoreErr && (
                          <Button type="text" style={{ color: '#528552', fontWeight: '700', marginBottom: '15px' }} onClick={showMoreSql} block>
                            Load More
                          </Button>
                        )}
                        {!showMoreLoading && showMoreErr && showMoreMsg && (
                          <Box sx={{ textAlign: 'center', paddingY: '5px', marginBottom: '15px' }} type="text" block>
                            {showMoreMsg}
                          </Box>
                        )}
                        {showMoreLoading &&
                          <div className="flex items-center justify-center w-full mb-5">
                            <SyncOutlined spin />
                          </div>
                        }
                      </Box>
                    )}
                    {!isloading && triggerHistory === "vis" && VisthreadsToShow?.length > 0 && (
                      <Box
                        sx={{ overflowX: "hidden", height: "67.5vh" }}
                        className="messages"
                      >
                        {VisthreadsToShow.map((thread) => (
                          <Button
                            type="text"
                            key={thread.ThreadId}
                            onClick={() => OpenTab(thread)}
                            style={{
                              paddingLeft: '5px',
                              paddingRight: '5px',
                              backgroundColor:
                                currTab === thread.TabId
                                  ? "#F0F0F0"
                                  : "transparent",
                              backgroundImage:
                                currTab === thread.TabId
                                  ? "none"
                                  : "linear-gradient(to right ,transparent,#d0e3d0) !important",
                              ":hover": {
                                backgroundColor: "#F0F0F0",
                              },
                            }}
                            className="btnhoverprop "
                            block
                          >
                            <ThreadName key={thread.TabId} thread={thread} />
                            {/* <Typography>{thread.ThreadName}</Typography> */}
                          </Button>
                        ))}
                        {!showMoreLoading && !showMoreErr && (
                          <Button type="text" style={{ color: '#528552', fontWeight: '700', marginBottom: '15px' }} onClick={showMoreVis} block>
                            Load More
                          </Button>
                        )}
                        {!showMoreLoading && showMoreErr && showMoreMsg && (
                          <Box sx={{ textAlign: 'center', paddingY: '5px', marginBottom: '15px' }} type="text" block>
                            {showMoreMsg}
                          </Box>
                        )}
                        {showMoreLoading &&
                          <div className="flex items-center justify-center w-full mb-5">
                            <SyncOutlined spin />
                          </div>
                        }
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box sx={{ position: "absolute", bottom: "0rem", width: '100%', backgroundColor: '#9dc09d', zIndex: 100 }}>
                  <Divider style={{ margin: 0 }} />
                  <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={["click"]}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      <Space>
                        <Box
                          display={isCollapsed ? "block" : "flex"}
                          sx={{
                            gap: "2",
                            display: "flex",
                            textAlign: "center",
                            p: "1",
                            marginLeft: "1rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar
                            size="xl"
                            name="username"
                            src={user.picture || "https://t3.ftcdn.net/jpg/03/64/62/36/360_F_364623623_ERzQYfO4HHHyawYkJ16tREsizLyvcaeg.jpg"}
                          />
                          <Typography
                            style={{ color: "rgb(15 65 10)", fontWeight: "bold" }}
                          >
                            {user.name}
                          </Typography>
                        </Box>
                      </Space>
                    </Link>
                  </Dropdown>
                </Box>
              </Box>
            </Menu>
          </ProSidebar>
        </Box>
        <Box
          className="mainContent flex-1 ml-[14rem]"
          sx={{ paddingTop: "0.1rem", position: "relative" }}
        >
          {/* <div
            class="fixed  top-1/2 z-40"
            // style={{ left:"11.5rem"}}
          >
            <Tooltip placement="right" title="Close sidebar" arrow={mergedArrow}>
            <Button type="text" onClick={() => setIsCollapsed(!isCollapsed)} padding="0"><LeftOutlined /></Button>
          </Tooltip> 
          </div> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: "100vh",
              width: "100%",
              xOverflow: "hidden",
              fontSize: "1rem",
              lineHeight: "1.5rem",
            }}
          >
            {/* <Typography.Title>Welcome to AI Dashboard</Typography.Title> */}
            <Tabs
              hideAdd
              animated
              className="fill-available"
              onChange={onChange}
              activeKey={activeKey}
              type="editable-card"
              onEdit={onEdit}
              items={tabs}
            />
            <Routes>
              <Route
                exact
                path="/playground/:type/:tabId"
                element={<SQLQA />}
              />
              <Route
                exact
                path="/playground/:type/:tabId"
                element={<Visualizer />}
              />
            </Routes>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AIdashboard;
