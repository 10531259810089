import React from "react";
import { Table, Button, Dropdown, Space, message } from "antd";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./DataPopup.css";
import { Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addData, addTab, incrementVisTabCount, updateCurrTab } from "../Redux/dataRedux";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import Visualizer from "./Visualizer";
import { v4 as uuidv4 } from 'uuid';

// const serverUrl = "https://nobrokerserver.officeink.live";
const serverUrl = "http://localhost:5000";

// This is a functional component that displays a popup with data
const DataPopup = ({ data }) => {
  // Use the Redux dispatch function
  const dispatch = useDispatch();
  const DataStore = useSelector((state) => state.dataReducer);
  const { isVisualizer } = DataStore;
  let tabId = DataStore.currTab;
  // Map the data columns to a format suitable for a table
  const columns =
    data?.columns?.map((column) => ({
      title: column,
      dataIndex: column,
      key: column,
    })) || [];

  // Get the rows from the data, or an empty array if no data
  const rows = data?.rows || [];
  rows.forEach(row => {
    for (let key in row) {
      if (row.hasOwnProperty(key)) {
          if(typeof row[key] === "number"){
            row[key] = row[key].toLocaleString("en-US");
        }
      }
  }
  });
  
  // Function to export the data as a CSV file
  const exportCSV = () => {
    // Convert the data to CSV format
    const csv = Papa.unparse({
      fields: data.columns,
      data: rows,
    });

    // Create a blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Use the FileSaver library to save the blob as a CSV file
    saveAs(blob, "data.csv");
  };

  // Function to export the data as an XLSX file
  const exportXLSX = () => {
    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(rows);

    // Create a new workbook and append the worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Write the workbook to an XLSX file
    XLSX.writeFile(workbook, "data.xlsx");
  };

  // Function to export the data as a PDF file
  const exportPDF = () => {
    // Create a new jsPDF document
    const doc = new jsPDF();

    // Add a table to the document with the data
    doc.autoTable({
      head: [data.columns],
      body: rows.map((row) => data.columns.map((column) => row[column])),
    });

    // Save the document as a PDF file
    doc.save("data.pdf");
  };

  // Function to handle click events on the download buttons
  const onClick = ({ key }) => {
    message.info(`Downloading ${key} file`);
  };

  // Define the items for the download menu
  const items = [
    {
      label: <Button style={{ width: '100%' }} className="shadow-md" onClick={exportCSV}>Export as CSV</Button>,
      key: "CSV",
    },
    {
      label: <Button style={{ width: '100%' }} className="shadow-md" onClick={exportXLSX}>Export as XLSX</Button>,
      key: "XLSX",
    },
    {
      label: <Button style={{ width: '100%' }} className="shadow-md" onClick={exportPDF}>Export as PDF</Button>,
      key: "PDF",
    },
  ];

  // Function to handle click events on the "Visualize" button
  const handleClick = async () => {
    const newActiveKey = `vis${uuidv4()}`
    dispatch(addData({ tabId: newActiveKey, tableData: data }));

    const newTab = {
      label: "vis new",
      key: newActiveKey,
      type: "vis",
      children: <Visualizer />
    };

    dispatch(addTab(newTab));
    dispatch(updateCurrTab(newActiveKey));
    dispatch(incrementVisTabCount())

    // Set the thread started flag in local storage to false
    localStorage.setItem("isThreadStarted", "false");

    try {
      // Convert the data to CSV format
      const csv = Papa.unparse({
        fields: data.columns,
        data: rows,
      });

      // Create a blob with the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create a FormData object and append the blob to it
      let formData = new FormData();
      formData.append("file", blob, "filename.csv"); // Replace 'filename.csv' with the actual file name

      // Send a POST request to the server to upload the file
      const res = await axios.post(
        `${serverUrl}/api/visualizer/upload`,
        formData
      );

      // Get the file ID from the response and store it in local storage
      const fileId = res.data.fileId;
      localStorage.setItem("fileId", fileId);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <Box>
      <Box
        className="datapopup"
        sx={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}
      >
        <div
          style={{
            border: "2px solid lightgray",
            borderRadius: "0.4rem",
            padding: "4px 15px",
            backgroundColor: "white",
          }}
          className="export-buttons"
        >
          <Dropdown
            menu={{
              items,
              onClick,
            }}
            placement="topRight"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <a href="/#" onClick={(e) => e.preventDefault()}>
              <Space>
                Export
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>

        {!isVisualizer && (
          <Button key={columns} onClick={handleClick}>
            {" "}
            {/* <Link to="/visualizer"> */}
            Visualize
            {/* </Link>{" "} */}
          </Button>
        )}
      </Box>

      <div className="table-wrapper">
        <Table
          key={columns + tabId}
          dataSource={rows.slice(0, 1000)}
          columns={columns}
          pagination={false}
          bordered={true}
          // loading={true}
          style={{ overflow: "auto", borderRadius: "0.5rem" }}
        />
      </div>
    </Box>
  );
};

export default DataPopup;
