import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Input,
  Space,
  Select,
  Spin,
  Steps,
  Tooltip,
  // Popover,
  Drawer,
} from "antd";
import ReactMarkdown from "react-markdown";
import DataPopup from "./DataPopup";
import LogsModal from "./LogsModal";
import axios from "axios";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addThreadIds, updateTabName } from "../Redux/dataRedux";
import { useNavigate } from "react-router-dom";
const { io } = require("socket.io-client");
let clientId;

// const serverUrl = "https://nobrokerserver.officeink.live";
const serverUrl = "http://localhost:5000";

const socket = io(serverUrl);

const SQLQA = () => {
  const navigate = useNavigate();
  const dataStore = useSelector((state) => state.dataReducer);
  const dispatch = useDispatch();
  let tabId = dataStore.currTab;
  const userId = dataStore.userData.ID;
  const sqlMsgThread = dataStore.sqlMsgThreads;
  const dataSource = dataStore.assistantIDs;
  const [messages, setMessages] = useState([]);
  const scrollableDivRef = useRef(null);
  const [prompt, setPrompt] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [viewLogs, setViewLogs] = useState(false);
  const [fetchStatus, setFetchStatus] = useState(""); // 'success', 'error', or ''
  const [step, setStep] = useState(0);
  const [logs, setLogs] = useState([]);
  const [options, setOptions] = useState([]);
  const [AssistIds, setAssistIds] = useState(null);
  const [AssistLabel, setAssistLabel] = useState(null);
  const [AssistOption, setAssistOption] = useState(null);
  const [PromptGuideQuestions, setPromptGuideQuestions] = useState(null);
  const [Glossaries, setGlossaries] = useState([]);
  const finalSql = [];
  // let defaultSelect;
  // Handle "attemptLogs" events from the socket
  socket.on("attemptLogs", (data) => {
    // Update the logs state
    setLogs((oldLogs) => {
      // Ensure oldLogs is an array
      const logsArray = Array.isArray(oldLogs) ? oldLogs : [];

      // Create a Map from oldLogs
      const logsMap = new Map(logsArray.map((log) => [log.attemptId, log]));

      // Update or add the new data based on attemptId
      logsMap.set(data.attemptId, data);

      // Convert the Map back to an array
      const updatedLogs = Array.from(logsMap.values());
      return updatedLogs;
    });
  });

  // Handle the "connect" event from the socket
  socket.on("connect", () => {
    // Store the socket ID
    clientId = socket.id;
  });

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      const newOptions = dataSource.map((assistant) => ({
        value: assistant.AssistantID,
        label: assistant.Name,
      }));
      setOptions(newOptions);
      if (AssistIds === null && AssistLabel === null && AssistOption === null) {
        setAssistIds(dataSource[0].AssistantID);
        setAssistLabel(dataSource[0].Name);
        setAssistOption(newOptions[0]);
      }
    }
  }, [dataSource, threadId]);

  useEffect(() => {
    const selectedAssistant = dataSource.find(
      (assistant) => assistant.AssistantID === AssistIds
    );
    if (messages.length === 0) {
      if (selectedAssistant && selectedAssistant.PromptGuide) {
        const promptGuide = JSON.parse(selectedAssistant.PromptGuide);
        const questions = promptGuide.map((item) => item.question);
        setPromptGuideQuestions(questions);
      }
    }

    const glossaryList = selectedAssistant
      ? JSON.parse(selectedAssistant.Glossaries)
      : [];
    setGlossaries(glossaryList);
  }, [messages, dataSource, AssistIds]);

const [userIds, setUserIds] = useState([]);
  // This effect runs when the messages state changes
  useEffect(() => {
    async function fetchData() {
      const authToken = localStorage.getItem("token");
      try {
        const isAdded = sqlMsgThread.find(
          (thread) => thread.ThreadId === threadId
        );
        if (messages.length === 1 && threadId && !isAdded) {
          let userID = [];
          userID?.push(userId);
          const res = await axios.post(
            `${serverUrl}/api/sql/addMsgThread`,
            {
              TabId: tabId,
              ThreadId: threadId,
              MsgJson: messages,
              UserId_fk: userID,
              AssistID: JSON.stringify(AssistOption),
            },
            {
              headers: {
                Auth_Token: authToken,
              },
            }
          );

          if (res.status === 200) {
            dispatch(addThreadIds({ tabId: tabId, threadId: threadId }));
            dispatch(
              updateTabName({ tabId: tabId, newTabName: res.data.ThreadName })
            );
          }
        } else if (userId !== undefined && threadId !== undefined) {
          // Fetch the current thread data
          const threadRes = await axios.get(
            `${serverUrl}/api/sql/getMsgThread/${tabId}`,
            {
              headers: {
                Auth_Token: authToken,
              },
            }
          );
          if (threadRes.status === 200) {
            //let userIds = JSON.parse(threadRes.data[0].UserId_fk);
            setUserIds(JSON.parse(threadRes.data[0].UserId_fk))
            // console.log("check 3 -- ", userIds)
            // Check if userId is included in the UserId_fk array
            if (!userIds?.includes(userId)) {
              // If not included, add it
              userIds?.push(userId);
            }
            const res = await axios.put(
              `${serverUrl}/api/sql/updateMsgThread/${threadId}`,
              {
                TabId: tabId,
                MsgJson: messages,
                UserId_fk: JSON.stringify(userIds),
                AssistID: JSON.stringify(AssistOption),
              },
              {
                headers: {
                  Auth_Token: authToken,
                },
              }
            );
            if (res.status === 200) {
            }
          }
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }

    // If there are any messages, store them in local storage
    if (messages.length > 0) {
      fetchData();
    }
    // If the scrollableDivRef is defined, scroll to the bottom
    if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  }, [messages, threadId, sqlMsgThread[threadId]]);

  // This effect runs when the logs state changes
  useEffect(() => {
    // Set the step state to the length of the logs
    setStep(logs.length);
    // If there are 5 or more logs, stop loading
    if (logs.length >= 5) {
      setLoading(false);
    }
  }, [logs]);

  // This function starts a new conversation
  async function startNewConversation(firstMessage) {
    // Reset the fetch status and logs state
    setFetchStatus("");
    setLogs([]);

    // If there is no threadId in local storage, start a new conversation
    const threadIds = JSON.parse(localStorage.getItem("threadId")) || {};
    if (threadIds[tabId] === undefined) {
      // Send a POST request to start a new conversation
      const response = await fetch(`${serverUrl}/startConversation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      // If the response is not ok, throw an error
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      setThreadId(data?.threadId);
      threadIds[tabId] = data?.threadId;
      localStorage.setItem("threadId", JSON.stringify(threadIds));
    } else {
      // If there is a threadId in local storage, set the threadId state to this threadId
      setThreadId(threadIds[tabId]);
      // setThreadId(localStorage.getItem("threadId"));
    }
  }

  // This function sends a message
  async function sendMessage() {
    var userMessage = prompt;

    if (userMessage !== "") {
      // Reset the prompt, fetch status, and logs state
      setPrompt("");
      setFetchStatus("");
      setLogs([]);

      try {
        // Get the response message from the OpenAI API
        var responseMessage = await getOpenAIResponse(userMessage);
        // Adapt the data from the response message
        const adaptedData = responseMessage?.rows?.map((row) => {
          let rowData = {};
          responseMessage?.columns.forEach((column, index) => {
            rowData[column] = row[index];
          });
          return rowData;
        });

        // If the response message contains columns and rows, append the message
        if (responseMessage?.columns && responseMessage?.rows) {
          finalSql.push({ sqlQuery: responseMessage?.query });
          const jsonstr = JSON.stringify({
            columns: responseMessage?.columns,
            rows: adaptedData,
            sqlLogs: finalSql,
          });
          appendMessage(jsonstr, "AI");
        }
        // Set the fetch status to success
        setFetchStatus("success");
        // Stop loading
        setLoading(false);
      } catch (error) {
        // If there is an error, set the fetch status to error and append an error message
        setFetchStatus("error");
        appendMessage("Error: " + error.message, "AI");
        setLoading(false);
      }
      // Hide the button loader
      toggleButtonLoader(false);
    }
  }

  // This function sends a request to the server and gets a response from the OpenAI API
  async function getOpenAIResponse(message) {
    try {
      setLoading(true);
      setFetchStatus("");
      const authToken = localStorage.getItem("token");
      const threadIds = JSON.parse(localStorage.getItem("threadId")) || {};
      if (threadIds[tabId] === undefined) {
        await startNewConversation(message);
      }

      // Send a POST request to the server
      const threadID = await JSON.parse(localStorage.getItem("threadId"))[
        tabId
      ];

    //   if (!userIds?.includes(userId)) {
    //     // If not included, add it
    //     console.log("check 1 in sqlqa ", userIds)
    //     userIds?.push(userId);
    //     console.log("check 2 in sqlqa ", userIds)
    //     const res = await axios.put(
    //       `${serverUrl}/api/sql/updateMsgThread/${threadId}`,
    //       {
    //         TabId: tabId,
    //         MsgJson: messages,
    //         UserId_fk: JSON.stringify(userIds),
    //         AssistID: JSON.stringify(AssistOption),
    //       },
    //       {
    //         headers: {
    //           Auth_Token: authToken,
    //       },
    //     }
    //   );
    //   if (res.status === 200) {
    //   }
    // }

      const response = await fetch(`${serverUrl}/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userMessage: message,
          threadId: threadID,
          assistId: AssistIds,
          clientId: clientId,
        }),
      });
      // Get the data from the response
      const data = await response.json();
      // If the response status is 201, append the message from the data to the messages and return it
      if (response.status === 201) {
        appendMessage(data.message, "AI");
        setLoading(false);
        return data.message;
      }
      // If the response status is not 201, return the result from the data
      return data.result;
    } catch (error) {
      // If there is an error, set the loading state to false and log the error message
      setLoading(false);
      console.log(`Error fetching data: ${error.message}`);
    }
  }

  function toggleButtonLoader(show) {}

  // This function appends a message to the messages state
  function appendMessage(text, sender) {
    setMessages((oldMessage) => [
      // Spread the old messages
      ...oldMessage,
      { message: text, sender: sender },
    ]);
  }

  // This function splits a message into parts
  function splitMessage(message) {
    var parts = [];
    console.log("bug check1 ", message);
    if (message?.includes("columns") && message?.includes("rows")) {
      parts.push({ tableData: JSON.parse(message) });
    } else {
      parts.push({ text: message });
    }
    return parts;
  }

  async function getTabMsgThread() {
    const authToken = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${serverUrl}/api/sql/getMsgThread/${tabId}`,
        {
          headers: {
            Auth_Token: authToken,
          },
        }
      );
      if (res.status === 200) {
        if (res.data.length > 0) {
          setThreadId(res.data[0].ThreadId);
          const threadIds = JSON.parse(localStorage.getItem("threadId")) || {};
          threadIds[tabId] = res.data[0].ThreadId;
          dispatch(
            updateTabName({ tabId: tabId, newTabName: res.data[0].ThreadName })
          );
          localStorage.setItem("threadId", JSON.stringify(threadIds));
          if (
            res.data[0].AssistantID !== "null" &&
            res.data[0].AssistantID !== null &&
            res.data.length > 0
          ) {
            const assistOption = JSON.parse(res.data[0].AssistantID);
            setAssistIds(assistOption?.value);
            setAssistLabel(assistOption?.label);
            setAssistOption(assistOption);
          }
          return JSON.parse(res.data[0].MsgJson);
        } else {
          return null;
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  // This effect runs when the component mounts
  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("token");
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const threadIds = JSON.parse(localStorage.getItem("threadId")) || {};

    if (threadIds) {
      setThreadId(threadIds[tabId]);

      //todo: call api to get data
      getTabMsgThread().then((data) => {
        if (data) {
          setMessages(data);
        } else {
          setMessages([]);
        }
      });
    }
  }, []);

  // This function handles changes to the assistIds state
  const handleChange = (value) => {
    setAssistIds(value);
    const assistOption = options.find((option) => option.value === value);
    setAssistOption(assistOption);
    setAssistLabel(assistOption.label);
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="pr-[.9rem] pt-1 h-full w-[-webkit-fill-available] ">
        <div className="border-2  rounded-md border-black flex items-center justify-between w-full flex-col p-4 pb-0">
          <div
            ref={scrollableDivRef}
            className=" messages p-4 w-full block overflow-y-auto h-[74.5vh]"
          >
            {" "}

            {messages.length > 0 &&
              messages.map((message, index) => {
                const messageParts = splitMessage(message?.message);
                return (
                  <div
                    className={
                      message?.sender === "AI"
                        ? "flex gap-2 items-end justify-start py-2"
                        : "flex gap-2 items-end flex-row-reverse py-2"
                    }
                    key={tabId + index}
                  >
                    <Avatar
                      style={{
                        backgroundColor: "#fde3cf",
                        color: "#f56a00",
                      }}
                    >
                      {message?.sender === "AI" ? "AI" : "H"}
                    </Avatar>
                    <div className="p-2 bg-[#e9e9e9] w-fit rounded-md shadow-md">
                      {messageParts.map((part, idx) => {
                        if (part.text) {
                          return (
                            <ReactMarkdown
                              key={idx + tabId}
                              id={idx}
                              className="text-sm overflow-hidden leading-7"
                            >
                              {part.text}
                            </ReactMarkdown>
                          );
                        }

                        if (part.tableData) {
                          return (
                            <>
                              <DataPopup
                                data={{
                                  columns: part.tableData?.columns,
                                  rows: part.tableData?.rows,
                                }}
                                key={idx + tabId}
                              />
                              <div mx={3}>
                                <div className="flex items-center gap-2 justify-center rounded-md pt-[4px]">
                                  <span className="font-semibold"> Final Query</span>
                                  <Tooltip title="View logs">
                                    <LogsModal
                                      key={idx}
                                      data={part.tableData?.sqlLogs}
                                      id={0}
                                      type="final"
                                    />
                                  </Tooltip>
                                    {part.tableData?.rows.length > 1000 && (
                                      <div className="text-center text-sm text-gray-500">
                                        Showing first 1,000 rows
                                      </div>
                                    )}
                                </div>
                              </div>
                            </>
                          );
                        }
                        return "";
                        // )
                      })}
                    </div>
                  </div>
                );
              })}
            {messages.length === 0 && (
              <div
                className="py-2 text-center top-10 relative"
                key="SQLQALoader"
              >
                <Box className="flex flex-col items-center justify-center gap-2">
                  <Avatar
                    style={{
                      backgroundColor: "#fde3cf",
                      color: "#f56a00",
                    }}
                  >
                    AI
                  </Avatar>
                  <div className="py-1 px-2 bg-[#e9e9e9] w-fit rounded-md">
                    <ReactMarkdown
                      id={0}
                      className="text-sm overflow-hidden leading-7"
                    >
                      {"Welcome to DATA QA, How can I help you?"}
                    </ReactMarkdown>
                  </div>
                </Box>
                {PromptGuideQuestions && PromptGuideQuestions.length > 0 && (
                  <div className="p-2 flex flex-wrap gap-1 relative top-10 bg-[#e9e9e9] w-fit rounded-md">
                    {PromptGuideQuestions.map((question, index) => (
                      <Button
                        key={index}
                        onClick={() => {
                          setPrompt(question);
                        }}
                      >
                        {question}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
            )}
            { loading &&(
              <div
                className="flex gap-2 items-end justify-start py-2"
                key="SQLQALoader"
              >
                <Avatar
                  style={{
                    backgroundColor: "#fde3cf",
                    color: "#f56a00",
                  }}
                >
                  AI
                </Avatar>
                <div className="flex items-center justify-center">
                  {loading && (
                    <div className="p-2 bg-[#e9e9e9] w-fit rounded-md">
                      <Spin />
                    </div>
                   )} 
                  <div className="p-2 w-[14rem]">
                    <div
                      className="bg-slate-200 m-1 flex items-center gap-2 justify-center rounded-md"
                      onClick={() => {
                        setViewLogs(!viewLogs);
                      }}
                    >
                      <span>Analyzing</span>
                      {viewLogs ? (
                        <CaretUpOutlined height="1em" />
                      ) : (
                        <CaretDownOutlined height="1em" />
                      )}
                    </div>
                    <Box className="p-2" display={viewLogs ? "block" : "none"}>
                      <Steps current={step} direction="vertical" size="small">
                        <Steps.Step
                          title={`Writing Query 1`}
                          subTitle={
                            <Tooltip title="View logs">
                              <LogsModal id={0} type="steps" data={logs} />{" "}
                            </Tooltip>
                          }
                        />
                        {logs.length > 0 &&
                          logs.map(
                            (step, index) =>
                              step.errorStatus && (
                                <Steps.Step
                                  key={index}
                                  title={`Writing Query ${step.attemptId + 1}`}
                                  subTitle={
                                    <Tooltip title="View logs">
                                      <LogsModal
                                        key={index}
                                        data={logs}
                                        id={step.attemptId}
                                      />{" "}
                                    </Tooltip>
                                  }
                                />
                              )
                          )}
                      </Steps>
                    </Box>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            name="prompt"
            className="w-[98%] p-2 flex justify-center"
            style={{ alignItems: "center" }}
          >
            {" "}
            <Box>
              {/* <Popover content={content} title="Galossaries"> */}
              <InfoCircleOutlined
                onClick={showDrawer}
                style={{ cursor: "pointer" }}
              />
              {/* </Popover> */}
              <Drawer
                title={`${AssistLabel} Glossaries`}
                placement="right"
                width={400}
                onClose={onClose}
                open={open}
                extra={
                  <Space>
                    {/* <Button onClick={onClose}>Cancel</Button> */}
                    <Button type="primary" onClick={onClose}>
                      OK
                    </Button>
                  </Space>
                }
              >
                {Glossaries !== null &&
                  Glossaries?.map((glossary, index) => {
                    const parts = glossary.description.split(":");
                    return (
                      <li
                        key={index}
                        style={{ color: "black", fontSize: "0.8rem" }}
                      >
                        {parts[0]}
                        {parts[1]?.length > 0 && ":"}
                        {parts[1] && (
                          <div className="left-6 relative">
                            <ul>
                              {parts[1].split(".").map(
                                (subitem, subIndex) =>
                                  subitem.length > 0 && (
                                    <li
                                      key={subIndex}
                                      style={{
                                        listStyleType: "none",
                                        color: "gray",
                                      }}
                                    >
                                      &rarr; {subitem}
                                    </li>
                                  )
                              )}
                            </ul>
                          </div>
                        )}
                      </li>
                    );
                  })}
              </Drawer>
            </Box>
            <Box sx={{ margin: "1rem" }}>
              {options[0] && options[0].label && (
                <Select
                  value={AssistIds}
                  style={{
                    width: 120,
                    height: '40px'
                  }}
                  placement="topRight"
                  onChange={handleChange}
                  options={options}
                />
              )}
            </Box>
            <Space.Compact style={{ width: "100%", height: "40px" }}>
              <Input
                name="prompt-input"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                disabled={loading}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && prompt !== "") {
                    setMessages((oldMessages) => [
                      ...oldMessages,
                      { sender: "user", message: prompt },
                    ]);
                    sendMessage();
                  }
                }}
              />
              <Button
                disabled={loading}
                type="primary"
                style={{ height: "100%" }}
                className={
                  loading ? "bg-[rgba(0, 0, 0, 0.04)]" : "bg-[#4096ff]"
                }
                onClick={() => {
                  if (prompt !== "") {
                    setMessages((oldMessages) => [
                      ...oldMessages,
                      { sender: "user", message: prompt },
                    ]);
                    sendMessage();
                  }
                }}
              >
                Submit
              </Button>
            </Space.Compact>
          </div>
        </div>
      </div>
    </>
  );
};

export default SQLQA;
